.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border-bottom: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: transparent;
    outline: none;
    border-bottom: 1px solid #ccc;
    font-size: 80%;
}

textarea {
    resize: none;
}

.InputElement:focus {
    border: transparent;
    outline: none;
    border-bottom: 1px solid #4285f4;
}

.Invalid {
    border: transparent;
    border-bottom: 1px solid red;
}