.App {
  width: 560px;
  margin: 0 auto;
}

.App p {
  color: gray;
  text-align: justify;
  font-size: 11px;
}

.Input {
  width: 500px;
  text-align: center;
  box-sizing: border-box;
}

.Row {
  margin-bottom: 40px;
  margin-right: 0;
  height: 40px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  block: row;
  align-items: center;
  height: 100%;
  margin-bottom: 50px;
}

.NavigationItems a {
  display: inline-block;
  font-size: 20px;
  color: rgb(39, 39, 39);
  width: auto;
}

.NavigationItems a img {
  display: inline-block;
  width: 110px;
  height: 110px;
  font-size: 20;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .NavigationItems {
    flex-flow: column;
  }
  .App {
    width: 70%;
    margin: 0 auto;
  }

  .NavigationItems a img {
    display: inline-block;
    width: 40%;
    height: auto;
    font-size: 20;
  }
  .NavigationItems a p {
    display: inline-block;
    width: 90%;
    height: auto;
    font-size: 20;
    color: gray;
  }
  
}


@media  (max-height: 370px) {
  .NavigationItems {
    flex-flow: column;
  }
  .App {
    width: 70%;
    margin: 0 auto;
  }

  .NavigationItems a img {
    display: inline-block;
    width: 50%;
    height: auto;
    font-size: 20;
  }
  .NavigationItems a p {
    display: inline-block;
    width: 90%;
    height: auto;
    font-size: 20;
    color: gray;
  }
  
}





