.App {
  text-align: center;
}

.AppLogo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.Height {
  height: 100%;
  width: 100%;
}

.AppText {
  font-family: 'Poppins', sans-serif;
  font-size: small;
  margin: 0;
  padding: 0;
}

.AppLink {
  color: rgb(41, 41, 41);
}

.SearchButton button {
  background-color: #4285f4;
  border: 1px solid #4285f4;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  margin: 30px 3px;
  min-width: 54px;
  padding: 10px 16px;
  font-weight: bold;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
}

.SearchButton button:hover, .SearchButton button:active, .SearchButton button.active {
  text-decoration: none;
  background: #699bec;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .15);
}

.SearchButton button:disabled {
  color: rgb(255, 113, 113);
  cursor: not-allowed;
}

.Justify {
  text-align: justify;
  margin-left: 10px;
}

.Row {
  margin-left: 10px;
  margin-right: 10px;
  width: auto;
  display: flex;
  flex-flow: row;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Logo {
  height: 35px;
  margin-bottom: 32px;
  margin-top: 32px;
}

.Auth {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .Auth {
    width: 500px;
  }
  .SearchButton button {
    font-size: 10px;
    margin: 0;
    min-width: 54px;
    padding: 7px 9px;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;
  }
}