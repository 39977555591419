.App {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  color: #4b4b4b;
}

.Input {
  width: 500px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  flex-flow: row;
}

.Row {
  width: 800px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-right: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.RowJust {
  width: 800px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-right: 0;
  padding: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
.Center {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.Button {
  margin-left: 15px;
  margin-right: 15px;
  }

@media (max-width: 600px), (max-height: 499px) {
  .App {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
  }
  .Row {
    text-align: justify;
    width: 80%;
  }
  .RowJust {
    width: 80%;
  }
  .Center {
    width: 90%;
  }
}