body {
  font-family: Sans-serif;
  font-size: 11px;
}

.slice {
  cursor: pointer;
}

.slice .main-arc {
  stroke: #fff;
  stroke-width: 1px;
}

.hidden-arc {
  fill: none;
}

.slice text {
  pointer-events: none;
  dominant-baseline: middle;
  text-anchor: middle;
}