.SigninButton {
    margin: auto;
    box-sizing: border-box;
    display: block;
    width: auto;
    white-space: nowrap;
}
.SigninButton a {
    box-sizing: border-box;
    display: block;
    font-size: 12px;
    height: 100%;
    width: auto;
    text-decoration: none;
    border: 1px solid #4285f4;
    border-radius: 2px;
    font-weight: bold;
    line-height: 28px;
    padding: 0 12px;
    color: #fff;
    cursor: pointer;
    background: #4285f4;
}

.SigninButton a:hover {
    text-decoration: none;
    background: #699bec;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .15);
}

@media (max-width: 499px), (max-height: 499px) {
    .SigninButton {
        margin: auto;
        box-sizing: border-box;
        display: block;
        width: auto;
        
    }
    .SigninButton a {
        box-sizing: border-box;
        display: block;
        height: 100%;
        width: auto;
        text-decoration: none;
        border: 1px solid #4285f4;
        border-radius: 2px;
        font-weight: bold;
        line-height: 28px;
        padding: 0 12px;
        color: #fff;
        cursor: pointer;
        background: #4285f4;
    }
    
    .SigninButton a:hover {
        text-decoration: none;
        background: #699bec;
        box-shadow: 0 2px 0 rgba(0, 0, 0, .15);
    }
} 
