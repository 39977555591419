.App {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  color: #4b4b4b;
}

.Input {
  width: 550px;
  text-align: justify;
  box-sizing: border-box;
}

.Row {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding-right: 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  text-align: justify;
  justify-content: center;
  width: 550px;
}

.Row div img {
  height: 40px;
  width: auto;
  margin-right: 15px;
}

.Center {
  text-align: center;
  justify-content: center;
}

.Width {
  height: 70px;
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px), (max-height: 499px) {
  .App {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
  }
  .Input {
    width: 70%;
  }
  .Row {
    width: 100%;
  }
}