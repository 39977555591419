.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color:  rgb(92, 92, 92);
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    font-size: 12px;

}

.NavigationItem a:hover, .NavigationItem a:active, .NavigationItem a.active {
    color: #000;
}

@media (min-width: 500px) and (min-height: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    .NavigationItem a {
        color: rgb(92, 92, 92);
	    font-weight: 500;
	    text-decoration: none;
        height: 100%;
        padding: 23px 10px;
        border-bottom: 4px solid transparent;
    }
    .NavigationItem a:hover, .NavigationItem a:active, .NavigationItem a.active {
        color: rgb(32, 32, 32);
        text-decoration: underline;
    } 
}