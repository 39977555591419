.Toolbar {
    height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    height: 60%;
}



@media (max-width: 499px), (max-height: 499px) {
    .Logo {
        display: none;
    }
}