.SearchButton button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #757575;
  cursor: pointer;
  font-size: 13px;
  /* margin: 30px 3px; */
  /* min-width: 54px; */
  /* padding: 10px 16px; */
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  /* margin-right: 5px; */
  /* margin-left: 5px; */
}

.SearchButton button img {
  width: 25px
}

.SearchButton button:hover, .SearchButton button:active, .SearchButton button.active {
  color: #5a5a5a;
  background-color: rgba(0, 255, 21, 0.1);
  text-decoration: none;
}

.SearchButton button:disabled {
  color: rgb(255, 113, 113);
  cursor: not-allowed;
}

@media (max-width: 600px), (max-height: 499px) {
  .SearchButton button {
    font-size: 10px;
    margin: 0;
    min-width: 54px;
    /* padding: 7px 9px; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    text-align: center;
    margin-right: 5px;
    margin-left: 0px;
  }
  .SearchButton button img {
    width: 20px
  }
}