.App {
  display: block;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  margin: auto;
  align-items: center;
  color: #4b4b4b;
  width: auto;
}

.Center {
  display: inline-block;
  margin: auto;
  width: 550px;
}

.Row {
  margin-top: 20px;
  display: inline-block;
  flex-flow: column;
  width: 550px;
}

.RowH {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.RowH div img {
  height: 40px;
  width: auto;
  margin-right: 15px;
}

.Text {
  font-size: 12px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: rgba(221, 221, 221, 0.15);
  border: 1px solid #eee;
  border-radius: 5px;
  display: inline-block;
  text-align: justify;
}

.Width {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  Width: 90px;
  text-align: center;
}

@media (max-width: 600px), (max-height: 499px) {
  .Center {
    width: 70%;
  }
  .Row {
    width: 70%;
  }
}