.SearchButton button {
  background-color: #4285f4;
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  margin: 30px 3px;
  min-width: 54px;
  /* padding: 10px 16px; */
  padding-top: 5px;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  /* margin-right: 5px; */
  font-weight: bold;

  margin-left: 5px;
}

.SearchButton button:hover, .SearchButton button:active, .SearchButton button.active {
  color: #fff;
  background-color: #699bec;
  text-decoration: none;
}

.SearchButton button:disabled {
  color: rgb(255, 113, 113);
  cursor: not-allowed;
}

@media (max-width: 600px), (max-height: 499px) {
  .SearchButton button {
    font-size: 10px;
    margin: 0;
    min-width: 54px;
    padding: 7px 9px;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;
  }
}