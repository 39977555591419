.WaffleIcon {
    margin: auto;
    text-align: center;
    box-sizing: border-box;
    display: block;
    width: auto;
    height: 40%;
    border: 1px solid transparent;
    border-radius: 2px;
    padding-left: 5px;
    padding-right: 5px;
}

.WaffleIcon img {
    width: 40px;
}

.WaffleIcon:hover, .WaffleIcon:active, .WaffleIcon.active {
    border: 1px solid #555555;
    border-radius: 5px;
}

.WaffleIcon a {
    font-size: 10px;
    color: black;
    width: auto;
}

.WaffleIcon a:hover, .WaffleIcon a:active, .WaffleIcon a.active {
    color: black;
    text-decoration: none;
}

@media (min-width: 500px) and (min-height: 500px) {
    .WaffleIcon {
        margin: auto;
        text-align: center;
        box-sizing: border-box;
        display: block;
        width: auto;
        height: 40%;
        border: 1px solid transparent;
        border-radius: 2px;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .WaffleIcon img {
        width: 60px;
    }
    
    .WaffleIcon:hover, .WaffleIcon:active, .WaffleIcon.active {
        border: 1px solid #555555;
        border-radius: 5px;
    }
    
    .WaffleIcon a {
        font-size: 13px;
        color: black;
        width: auto;
    }
    
    .WaffleIcon a:hover, .WaffleIcon a:active, .WaffleIcon a.active {
        color: black;
        text-decoration: none;
    }
}