.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
}

.Social {
    display: flex;
    text-align: right;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    align: right;
}

.Ico {
    margin-right: 10px;
}

@media (max-width: 499px), (max-height: 499px)   {
    .NavigationItems {
        flex-flow: row;
    }

    .SignIn {
        display: none;
    }
}
