.Waffle {
    margin: auto;
    box-sizing: border-box;
    display: block;
    width: auto;
    height: 40%;
}

.Waffle button img {
    width: 20px;
    height: 20px;
}

.Waffle button {
    background-color: transparent;
    border:none;
    padding: 0px;
    padding-left: 10px;
    padding-right: 20px;
    width: auto;
    outline:none;
    cursor: pointer;
    height: 30%;
    opacity: .5;
}

.Waffle button:hover, .Waffle button:active, .Waffle button.active {
    opacity: 1;

    text-decoration: none;
}

.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
}

@media (min-width: 500px) and (min-height: 500px) {
    .NavigationItems {
        flex-flow: row;
    }
}