.SearchButton button {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  color: #757575;
  cursor: pointer;
  font-size: 13px;
  margin: 30px 3px;
  min-width: 54px;
  padding: 10px 16px;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
}

.SearchButton button:hover, .SearchButton button:active, .SearchButton button.active {
  color: #5a5a5a;
  background-color: #e2dfdf;
  text-decoration: none;
}

.SearchButton button:disabled {
  color: rgb(255, 113, 113);
  cursor: not-allowed;
}

@media (max-width: 600px), (max-height: 499px) {
  .SearchButton button {
    font-size: 10px;
    margin: 0;
    min-width: 54px;
    padding: 7px 9px;
    text-align: center;
    margin-right: 0px;
    margin-left: 0px;
  }
}