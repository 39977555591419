.ToolbarFooter {
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: #f2f2f2;
    border-top: 1px solid #e4e4e4;
    box-sizing: border-box;
}

.ToolbarFooter nav {
    height: 100%;
}

.Logo {
    height: 60%;
}

@media (max-width: 499px), (max-height: 499px) {
    .ToolbarFooter {
        display: none;
    }
}