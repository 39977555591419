
.Address {
    width: auto;
    text-align: left;
    margin-bottom: 10px
}
.InputElementTrue {
    outline: none;
    background-color: rgba(0, 129, 0, 0.1);
    border: 1px solid #eee;
    border-radius: 5px;
    display: flex;  
}

.InputElementTrue p {
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 12px;
}
.Div {
    padding-top: 10px; 
    padding-bottom: 5px; 
}
.InputElementTrue div {
    text-align: center;
    margin: auto;
    padding-left: 10px;
    padding-right: 15px;
}

.InputElementTrue button {
    background-color: rgba(0, 129, 0, 0.2);
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    color: #f2f2f2;
    cursor: pointer;
    font-size: 13px;
    margin: 0;
    min-width: 54px;
    padding: 7px 16px;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
}

.InputElementTrue button:hover, .InputElementTrue button:active, .InputElementTrue button.active {
    color: #5a5a5a;
    background-color: rgba(0, 129, 0, 0.1);
    text-decoration: none;
}

.InputElementFalse {
    outline: none;
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid #eee;
    border-radius: 5px;
    display: flex;
    font-size: 12px;
}

.InputElementFalse p {
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 12px;
}

.InputElementFalse div {
    text-align: center;
    margin: auto;
    padding-left: 10px;
    padding-right: 20px;
}

.InputElementFalse button {
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    color: #f2f2f2;
    cursor: pointer;
    font-size: 13px;
    margin: 0;
    min-width: 54px;
    padding: 7px 16px;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
}

.InputElementFalse button:hover, .InputElementFalse button:active, .InputElementFalse button.active {
    color: #5a5a5a;
    background-color: rgba(255, 0, 0, 0.1);
    text-decoration: none;
}

@media (min-width: 600px) and (min-height: 500px) {
    .Address {
        width: 580px;
        padding: 10px;
        box-sizing: border-box;
    }
    .InputElementFalse p {
        font-size: 15px;
    }
    .InputElementFalse button {
        font-size: 15px;
    }
    .InputElementTrue p {
        font-size: 15px;
    }
    .InputElementTrue button {
        font-size: 15px;
    }
}